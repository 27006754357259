import "./src/styles/global.css"

require("typeface-rubik");



export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      require('intersection-observer')
      console.log(`# IntersectionObserver is polyfilled!`)
    }
  }